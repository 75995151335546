import React, {useEffect, useState} from "react";
import Webcam from "react-webcam";
import {v4 as uuidv4} from 'uuid';
import {Processing} from "./processing";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
};

export const LeftHandPhoto = props => {
    const webcamRef = React.useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [numberOfErrors, setNumberOfErrors] = useState(0);

    useEffect(() => {
        if (numberOfErrors > 0) {
            props.setStep(props.step + 1)
        }
    }, [numberOfErrors]);

    const capture = (e) => {
        e.preventDefault();
        if (webcamRef.current.getScreenshot()) {
            fetch(webcamRef.current.getScreenshot())
                .then(res => res.blob())
                .then(blob => {
                    const formdata = new FormData();
                    const file = new File([blob], "filename.jpeg");
                    formdata.append('image', file, uuidv4())
                    setIsLoading(true);

                    fetch(`https://content-admin.appyfurious.com/api/v5/analyzeHand`, {
                        method: 'POST',
                        body: formdata
                    })
                        .then((response) =>
                            response.json()
                        )
                        .then((data) => {
                            if (data.hand === 'LEFT') {
                                props.setStep(props.step + 1)
                                setIsLoading(false)
                            } else if (data.hand === 'RIGHT') {
                                setNumberOfErrors(num => num + 1)
                                setIsLoading(false);
                                setError('Please, use your left hand.');
                            } else {
                                setNumberOfErrors(num => num + 1)
                                setIsLoading(false);
                                setError('Please, add an image of your hand.');
                            }

                        })
                        .catch(() => {
                            setNumberOfErrors(num => num + 1)
                            setIsLoading(false);
                            setError('Error, try again.');
                        });
                })
        } else {
            setNumberOfErrors(e => e + 1)
            setIsLoading(false);
            setError('Error, try again.');
        }

    }

    if (isLoading) {
        return <Processing/>
    }

    return (
        <div className="quiz-step quiz-capture">
            <div className="quiz-header">
                <div className="quiz-header__title h5">
                    We do not collect your biometric data
                </div>
                {error ? (
                    <div className="quiz-form__input__error">{error}</div>
                ) : null}
            </div>
            <div className="quiz-capture__image">
                <Webcam
                    audio={false}
                    height={720}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={1280}
                    videoConstraints={videoConstraints}
                />

            </div>
            <button onClick={capture} className="quiz-capture__button">Capture photo</button>
        </div>
    );
};
