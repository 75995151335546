import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import {LeftHandPhoto, Processing, Profile, RightHandPhoto, Step7, Step8, Subscription} from '../forms/index';
import {observer} from "mobx-react";
import firebase from "firebase/app";
import {Redirect} from "react-router-dom";
import {getCookie} from "../helpers/payment/CookieHelper";

const renderStep = (step, values, errors, touched, setFieldValue, setStep) => {
    switch (step) {
        case 9:
            return <Step7 errors={errors} touched={touched} step={step} values={values} setStep={setStep}
                          setFieldValue={setFieldValue}/>;
        case 10:
            return <LeftHandPhoto errors={errors} touched={touched} step={step} values={values} setStep={setStep}
                                  setFieldValue={setFieldValue}/>;
        case 11:
            return <Step8 errors={errors} touched={touched} step={step} values={values} setStep={setStep}
                          setFieldValue={setFieldValue}/>;
        case 12:
            return <RightHandPhoto errors={errors} touched={touched} step={step} values={values} setStep={setStep}
                                   setFieldValue={setFieldValue}/>;
        case 13:



            return <Processing errors={errors} touched={touched} step={step} values={values}
                               setFieldValue={setFieldValue}/>;
        case 14:

            // console.log('pre-render subscription');
            // console.log('firebase', firebase);
            // let user = firebase.auth().currentUser;
            // if (user) {
                // console.log(user.uid);
            // } else {
                // console.log('no auth');
            // }

            return <Subscription errors={errors} touched={touched} step={step} values={values}
                                 setFieldValue={setFieldValue}/>;
        case 15:
            return <Profile errors={errors} touched={touched} step={step} values={values}
                            setFieldValue={setFieldValue}/>;
        default:
            return <Step7 errors={errors} touched={touched} step={step} values={values}/>;
    }
};

export const Stage2 = observer(props => {
    // console.log("cookie step: " + getCookie("step"))
    const [step, setStep] = useState(!isNaN(getCookie("step")) ? parseInt(getCookie("step")) : 9);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (props.person.uid) {
            setIsLoading(true)
            const db = firebase.firestore();
            const userRef = db.collection('users').where('uid', '==', props.person.uid);
            userRef.get().then(data => {
                if (!data.empty) {
                    data.forEach(item => {
                        db.collection('users').doc(item.id).get().then(foundUser => {
                            // console.error(foundUser.data())

                            if (foundUser.exists && foundUser.data().finished_stage_2) {
                                setShouldRedirect(true)
                            }
                            setIsLoading(false)

                        })
                    })
                }
            })
        }
        const id = localStorage.getItem('user_id')

        if (!id) {
            setShouldRedirect(true)
        }
    }, [])

    const formData = {
        name: props.person.name,
        gender: props.person.gender,
        dayOfBirth: props.person.dateOfBirth.day,
        monthOfBirth: props.person.dateOfBirth.month,
        yearOfBirth: props.person.dateOfBirth.year,
        hourOfBirth: props.person.timeOfBirth.hour,
        minuteOfBirth: props.person.timeOfBirth.minute,
        timeFormat: props.person.timeOfBirth.format,
        maritalStatus: props.person.maritalStatus,
        email: props.person.email,
        loggedIn: !!props.person.uid,
        uid: props.person.uid
    };

    const handleSubmit = () => {
        // console.log("submit step" + step);
        if (step != 14) {
            setStep(step => step + 1);
            window.scrollTo(0, 0)
        }
    };

    const validate = values => {
        const errors = {};
        return errors;
    };

    if (shouldRedirect) {
        return <Redirect to={'/'}/>
    }
    if (isLoading) {
        return <Processing/>
    }


    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{...formData}}
                onSubmit={handleSubmit}
                validate={validate}
            >
                {({values, errors, touched, setFieldValue}) => (
                    <div className="quiz-wrapper">
                        <div className="quiz-wrapper__inner">
                            <Form className="quiz-form">
                                {renderStep(step, values, errors, touched, setFieldValue, setStep)}
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
})
