import React from "react";
import {StepButton} from "../components/StepButton";
import {Field} from "formik";
import BackButton from "../components/BackButton";

export class Step3 extends React.Component {

    constructor() {
        super();
        this.state = {
            DayOfBirth: '',
            MonthOfBirth: '',
            YearOfBirth: ''
        }
        this.handleChangeDayOfBirth = this.handleChangeDayOfBirth.bind(this);
        this.handleChangeMonthOfBirth = this.handleChangeMonthOfBirth.bind(this);
        this.handleChangeYearOfBirth = this.handleChangeYearOfBirth.bind(this);
    }

    handleChangeDayOfBirth(e) {
        if (e.target.value >= 0 && e.target.value <= 31) {
            this.setState({DayOfBirth: e.target.value});
            this.props.values.dayOfBirth = e.target.value;
        }
    }

    handleChangeMonthOfBirth(e) {
        this.setState({MonthOfBirth: e.target.value});
        this.props.values.monthOfBirth = e.target.value;
    }

    handleChangeYearOfBirth(e) {
        if (e.target.value >= 0 && e.target.value <= 2020) {
            this.setState({YearOfBirth: e.target.value});
            this.props.values.yearOfBirth = e.target.value;
        }
    }

    onKeyDown = (keyEvent) => {
        if (!(this.props.values.dayOfBirth) && !(this.props.values.monthOfBirth) && !(this.props.values.yearOfBirth) && (keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    render() {
        return (
            <div className="quiz-step">
                <div className="quiz-header">
                    <BackButton handleBack={this.props.handleBack} />
                    <div className="quiz-header__counter">
                        Step 3 of 8
                    </div>
                    <div className="quiz-header__title h2">
                        What’s your date of birth?
                    </div>
                </div>
                <div className="quiz-input">
                    <div className="quiz-input__date">
                        <div className="select-field">
                            <Field
                                name="dayOfBirth"
                                value={this.state.dayOfBirth}
                                as="select">
                                <option value="" selected="true" disabled="true">Day</option>
                                <option value="1" className="option">1</option>
                                <option value="2" className="option">2</option>
                                <option value="3" className="option">3</option>
                                <option value="4" className="option">4</option>
                                <option value="5" className="option">5</option>
                                <option value="6" className="option">6</option>
                                <option value="7" className="option">7</option>
                                <option value="8" className="option">8</option>
                                <option value="9" className="option">9</option>
                                <option value="10" className="option">10</option>
                                <option value="11" className="option">11</option>
                                <option value="12" className="option">12</option>
                                <option value="13" className="option">13</option>
                                <option value="14" className="option">14</option>
                                <option value="15" className="option">15</option>
                                <option value="16" className="option">16</option>
                                <option value="17" className="option">17</option>
                                <option value="18" className="option">18</option>
                                <option value="19" className="option">19</option>
                                <option value="20" className="option">20</option>
                                <option value="21" className="option">21</option>
                                <option value="22" className="option">22</option>
                                <option value="23" className="option">23</option>
                                <option value="24" className="option">24</option>
                                <option value="25" className="option">25</option>
                                <option value="26" className="option">26</option>
                                <option value="27" className="option">27</option>
                                <option value="28" className="option">28</option>
                                <option value="29" className="option">29</option>
                                <option value="30" className="option">30</option>
                                <option value="31" className="option">31</option>
                            </Field>
                        </div>
                        <div className="select-field">
                            <Field
                                name="monthOfBirth"
                                value={this.state.monthOfBirth}
                                as="select">
                                <option value="" selected="true" disabled="true">Month</option>
                                <option value="1" className="option">January</option>
                                <option value="2" className="option">February</option>
                                <option value="3" className="option">March</option>
                                <option value="4" className="option">April</option>
                                <option value="5" className="option">May</option>
                                <option value="6" className="option">June</option>
                                <option value="7" className="option">July</option>
                                <option value="8" className="option">August</option>
                                <option value="9" className="option">September</option>
                                <option value="10" className="option">October</option>
                                <option value="11" className="option">November</option>
                                <option value="12" className="option">December</option>
                            </Field>
                        </div>
                        <div className="select-field">
                            <Field
                                name="yearOfBirth"
                                value={this.state.yearOfBirth}
                                as="select">
                                <option value="" selected="true" disabled="true">Year</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                                <option value="2004">2004</option>
                                <option value="2003">2003</option>
                                <option value="2002">2002</option>
                                <option value="2001">2001</option>
                                <option value="2000">2000</option>
                                <option value="1999">1999</option>
                                <option value="1998">1998</option>
                                <option value="1997">1997</option>
                                <option value="1996">1996</option>
                                <option value="1995">1995</option>
                                <option value="1994">1994</option>
                                <option value="1993">1993</option>
                                <option value="1992">1992</option>
                                <option value="1991">1991</option>
                                <option value="1990">1990</option>
                                <option value="1989">1989</option>
                                <option value="1988">1988</option>
                                <option value="1987">1987</option>
                                <option value="1986">1986</option>
                                <option value="1985">1985</option>
                                <option value="1984">1984</option>
                                <option value="1983">1983</option>
                                <option value="1982">1982</option>
                                <option value="1981">1981</option>
                                <option value="1980">1980</option>
                                <option value="1979">1979</option>
                                <option value="1978">1978</option>
                                <option value="1977">1977</option>
                                <option value="1976">1976</option>
                                <option value="1975">1975</option>
                                <option value="1974">1974</option>
                                <option value="1973">1973</option>
                                <option value="1972">1972</option>
                                <option value="1971">1971</option>
                                <option value="1970">1970</option>
                                <option value="1969">1969</option>
                                <option value="1968">1968</option>
                                <option value="1967">1967</option>
                                <option value="1966">1966</option>
                                <option value="1965">1965</option>
                                <option value="1964">1964</option>
                                <option value="1963">1963</option>
                                <option value="1962">1962</option>
                                <option value="1961">1961</option>
                                <option value="1960">1960</option>
                                <option value="1959">1959</option>
                                <option value="1958">1958</option>
                                <option value="1957">1957</option>
                                <option value="1956">1956</option>
                                <option value="1955">1955</option>
                                <option value="1954">1954</option>
                                <option value="1953">1953</option>
                                <option value="1952">1952</option>
                                <option value="1951">1951</option>
                                <option value="1950">1950</option>
                                <option value="1949">1949</option>
                                <option value="1948">1948</option>
                                <option value="1947">1947</option>
                                <option value="1946">1946</option>
                                <option value="1945">1945</option>
                                <option value="1944">1944</option>
                                <option value="1943">1943</option>
                                <option value="1942">1942</option>
                                <option value="1941">1941</option>
                                <option value="1940">1940</option>
                                <option value="1939">1939</option>
                                <option value="1938">1938</option>
                                <option value="1937">1937</option>
                                <option value="1936">1936</option>
                                <option value="1935">1935</option>
                                <option value="1934">1934</option>
                                <option value="1933">1933</option>
                                <option value="1932">1932</option>
                                <option value="1931">1931</option>
                                <option value="1930">1930</option>
                                <option value="1929">1929</option>
                                <option value="1928">1928</option>
                                <option value="1927">1927</option>
                                <option value="1926">1926</option>
                                <option value="1925">1925</option>
                                <option value="1924">1924</option>
                                <option value="1923">1923</option>
                                <option value="1922">1922</option>
                                <option value="1921">1921</option>
                                <option value="1920">1920</option>
                                <option value="1919">1919</option>
                                <option value="1918">1918</option>
                                <option value="1917">1917</option>
                                <option value="1916">1916</option>
                                <option value="1915">1915</option>
                                <option value="1914">1914</option>
                                <option value="1913">1913</option>
                                <option value="1912">1912</option>
                                <option value="1911">1911</option>
                                <option value="1910">1910</option>
                                <option value="1909">1909</option>
                                <option value="1908">1908</option>
                                <option value="1907">1907</option>
                                <option value="1906">1906</option>
                                <option value="1905">1905</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <StepButton step={this.props.step} errors={this.props.errors} values={this.props.values}/>
            </div>
        );
    }
};
