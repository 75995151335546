import React, { useState } from 'react';
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  ThreeDSecureAction,
  useRecurly,
} from '@recurly/react-recurly';
import { Link } from 'react-router-dom';
import { getCookie, setCookie } from '../helpers/payment/CookieHelper';
import Group41 from '../assets/images/Group_41.png';
import PayPalButton from '../assets/images/paypal.png';
import Constants from '../helpers/payment/Constants';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app';
import { observable } from 'mobx';
import observablePersonStore from '../stores/user-store';
import { APP_CONSTANTS, DEFAULT_PLAN } from '../constants/app';

export const SubScriptionInner = inject('store')(
  observer((props) => {
    const formRef = React.useRef();
    const recurly = useRecurly();
    const sca = React.useRef();
    const [actionTokenId, setActionTokenId] = useState('');
    const [localSub, setSubscription] = useState(null);
    const [localToken, setToken] = useState('');

    const [errors, setErrors] = useState({ month: '', year: '', cvv: '', number: '', first_name: '', last_name: '' });
    const [globalError, setGlobalError] = useState('');

    const removeError = (name) => {
      setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      jQuery('span#button_text').text('Processing ...');
      jQuery('span#button_price').text('');
      console.error(formRef.current);
      setGlobalError('');
      setErrors({});
      recurly.token(formRef.current, (err, token) => {
        // console.error(token);
        if (err) {
          jQuery('span#button_text').text('Get my results');
          jQuery('#submit_reg_btn').attr('operational', 'yes');
          // console.error(err, err.details.reduce((result, detail) => ({
          //     ...result,
          //     [detail.field]: detail.messages[0]
          // })), {});
          setErrors(
            err.details.reduce(
              (result, detail) => ({
                ...result,
                [detail.field]: detail.messages[0],
              }),
              {}
            )
          );
          setGlobalError(err.message);
        } else if (token && token.id) {
          // recurly returns token which should be passed to subtrack

          // createSubscription(token)
          jQuery('span#button_text').text('Subscribe');
          jQuery('span#button_price').text(`${props.store.plan.setupFee.toFixed(2)}$`);
          setGlobalError('Sorry, something went wrong.');
        }
      });
    };

    const handlePaypal = (event) => {
      event.preventDefault();
      const paypal = recurly.PayPal({
        display: { displayName: 'Palmistry' },
      });

      console.log('pp start');
      paypal.on('error', function (err) {
        console.log('error', err);
        setGlobalError(err.message);
      });

      paypal.start();

      paypal.on('token', function (token) {
        console.log('pp token ok');
        createSubscription(token);
        setGlobalError('');
        setErrors({});
      });
    };

    const createSubscription = (token, scaToken, code) => {
      // TODO: check if new user or not
      if (jQuery('#submit_reg_btn').attr('operational') === 'yes') {
        jQuery('#submit_reg_btn').attr('operational', 'no');
        let user = firebase.auth().currentUser;
        if (user) {
          fetch('https://subvalid.devroqapps.com/rec/create-subscription', {
            method: 'post',
            // mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createSubscriptionRequestBodyForExistingUser(token, scaToken, code)),
          })
            .then((response) => response.json())
            .then(
              // TODO: validation errors?
              (response) => (response.error ? handleExisting() : handleSubsciption(response, token))
            )
            .catch(() => {
              // TODO: validation errors?
              handleExisting();
              jQuery('#submit_reg_btn').attr('operational', 'yes');
            });
        } else {
          fetch('https://subvalid.devroqapps.com/rec/create-subscription-no-user', {
            method: 'post',
            // mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createSubscriptionRequestBodyForEmptyUser(token, scaToken, code)),
          }).then((response) => {
            // console.log("first cascade response here")
            // console.log(response)
            response
              .json()
              .then((response) => {
                // console.log("got a response")
                response.error ? handleExisting() : handleSubsciption(response, token);
              })
              .catch(() => {
                // TODO: validation errors?
                handleExisting();
                jQuery('#submit_reg_btn').attr('operational', 'yes');
              });
          });
        }
      }
    };

    const handleThreeDSecureToken = (scaToken) => {
      setActionTokenId(null);
      createSubscription(localToken, scaToken, localSub.code);
    };

    const handleThreeDSecureError = (err) => {
      // TODO: validation errors?
      setActionTokenId(null);
    };

    const handleSubsciption = (subscription, token) => {
      jQuery('span#button_text').text('Get my results');
      jQuery('#submit_reg_btn').attr('operational', 'yes');
      // console.log("handling subscription ...")
      // console.log(subscription)
      setSubscription(subscription);
      setToken(token);

      if (subscription.actionTokenId) {
        // TODO: setting 3dsecure - not sure if it's working
        setActionTokenId(subscription.actionTokenId);
      } else {
        // console.log("order complete");
        orderComplete(subscription);
      }
    };

    const handleExisting = () => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-Appy-Key': '3lPWXo2VIJmK' },
        body: JSON.stringify({ email: props.store.person.email }),
      };

      let subscription = {};

      fetch('https://us-central1-new-live-palm-reader.cloudfunctions.net/getSub', requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.empty) {
            subscription = {};
          } else {
            subscription = data;
            showCongratsNew(subscription);
            setSubscription(subscription);
          }

          // console.log("Sub: " + isSub)
          jQuery('span#button_text').text('Get my results');
          jQuery('#submit_reg_btn').attr('operational', 'yes');
        });
    };
    const orderComplete = (subscription) => {
      // console.log("state: " + subscription.state)
      if (subscription.state === 'active') {
        // console.log("track start")
        trackPurchase(subscription);
        // console.log("congrats")
        showCongrats(subscription);
        // TODO: set order is completed to user profile ?
      } else {
        // console.log("state not active")
        // console.error('ERROR')
      }
    };

    const trackPurchase = (subscription) => {
      const currency = subscription.currency ? subscription.currency.toUpperCase() : undefined;
      const sum = subscription.hasOwnProperty('initialPrice') ? subscription.initialPrice : undefined;

      // console.log("track purch: " + currency + sum)
      fbq('track', 'Subscribe', {
        value: sum,
        currency: currency,
      });
      // snaptr('track', 'PURCHASE', {
      //     currency: currency,
      //     price: sum,
      //     transaction_id: subscription.id,
      // });
    };
    const showCongrats = (subscription) => {
      // console.log("congrats cookies")
      setCookie(
        Constants.tutorialStateCookie,
        JSON.stringify({
          email: subscription.account.email,
          subscriptionId: subscription.id,
        })
      );
      setCookie('step', 15);
      setCookie('subId', subscription.id);
      // console.log("congrats timeout redirect")
      setTimeout(() => {
        document.location.href = '/#/stage-2';
        window.location.reload();
      }, 50);
    };

    const showCongratsNew = (subscription) => {
      // console.log("congrats cookies")
      setCookie(
        Constants.tutorialStateCookie,
        JSON.stringify({
          email: subscription.email,
          subscriptionId: subscription.id,
        })
      );
      setCookie('step', 15);
      setCookie('subId', subscription.id);
      // console.log("congrats timeout redirect")
      setTimeout(() => {
        document.location.href = '/#/stage-2';
        window.location.reload();
      }, 50);
    };

    const getPlanForCode = (code) => {
      let plan = {
        ...DEFAULT_PLAN,
        paypalEnabled: false,
        customPage: false,
      };

      props.store.setPlan(plan, 'lpr_2months');
      // console.log("fetching plan " + code)

      // const requestOptions = {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ plan: code }),
      // };

      // let success = false;

      // let plan = null;

      // fetch('https://us-central1-new-live-palm-reader.cloudfunctions.net/getPlan', requestOptions)
      //   .then((response) => {
      //     return response.json();
      //   })
      //   .then((data) => {
      //     if (data.empty) {
      //       success = false;
      //     } else {
      //       success = data.success;

      //       if (success) {
      //         plan = data.plan;
      //       }
      //     }

      //     if (plan != null) {
      //       props.store.setPlan(plan, code);
      //     } else {
      //       console.log('ELS');
      //       plan = {
      //         ...DEFAULT_PLAN,
      //         paypalEnabled: false,
      //         customPage: false,
      //       };

      //       props.store.setPlan(plan, 'lpr_2months');
      //     }
      //   })
      //   .catch((err) => {
      //     // console.log(err);

      //     plan = DEFAULT_PLAN

      //     props.store.setPlan(plan, 'lpr_2months');
      //   });
    };

    const createSubscriptionRequestBodyForExistingUser = (token, scaToken, code) => {
      var user = firebase.auth().currentUser;
      if (user) {
        return {
          token_id: token.id,
          sca_token_id: scaToken ? scaToken.id : undefined,
          code: code,
          plan_id: props.store.plan.id,
          email: props.store.person.email || 'pas@er.ru',
          zip: undefined,
          attribution: {
            campaign_id: getCookie('camp_id'),
            campaign_name: getCookie('camp_name'),
            adset_id: getCookie('adset_id'),
            adset_name: getCookie('adset_name'),
            ad_id: getCookie('ad_id'),
            ad_name: getCookie('ad_name'),
            country_code: getCookie('country_code'),
            fbc: getCookie('_fbc'),
            fbp: getCookie('_fbp'),
          },
          firebase_id: user ? user.uid : '',
        };
      } else {
        return {
          token_id: token.id,
          sca_token_id: scaToken ? scaToken.id : undefined,
          code: code,
          plan_id: props.store.plan.id,
          email: props.store.person.email || 'pas@er.ru',
          zip: undefined,
          attribution: {
            campaign_id: getCookie('camp_id'),
            campaign_name: getCookie('camp_name'),
            adset_id: getCookie('adset_id'),
            adset_name: getCookie('adset_name'),
            ad_id: getCookie('ad_id'),
            ad_name: getCookie('ad_name'),
            country_code: getCookie('country_code'),
            fbc: getCookie('_fbc'),
            fbp: getCookie('_fbp'),
          },
          firebase_id: user ? user.uid : '',
        };
      }
    };

    const createSubscriptionRequestBodyForEmptyUser = (token, scaToken, code) => {
      return {
        token_id: token.id,
        sca_token_id: scaToken ? scaToken.id : undefined,
        code: code,
        plan_id: props.store.plan.id,
        email: props.store.person.email || 'pas@er.ru',
        zip: undefined,
        attribution: {
          campaign_id: getCookie('camp_id'),
          campaign_name: getCookie('camp_name'),
          adset_id: getCookie('adset_id'),
          adset_name: getCookie('adset_name'),
          ad_id: getCookie('ad_id'),
          ad_name: getCookie('ad_name'),
          country_code: getCookie('country_code'),
          fbc: getCookie('_fbc'),
          fbp: getCookie('_fbp'),
        },
      };
    };

    const plan_code = getCookie('offer');

    getPlanForCode(plan_code);
    // console.log("plan fetched");

    const date = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    date.setDate(date.getDate() + props.store.plan.trialLength);
    const formatted = date.toLocaleDateString('en-US', options);
    // console.log("f date > " + formatted);

    const cid = getCookie('camp_id');
    // console.log("cid")
    // console.log(cid)
    // console.log(cid === undefined)

    // fbq('track', 'InitiateCheckout')

    return (
      <div className="quiz-subscription">
        <div className="quiz-subscription__wrapper">
          <div className="quiz-subscription__header">
            <div className="quiz-subscription__sliding"></div>
          </div>
          <div className="quiz-subscription__inner">
            <div className="quiz-subscription__results">
              <div className="quiz-subscription__title">Your results are ready</div>
              <div className="quiz-subscription__subtitle">
                Use our smart predictions by industry experts to be prepared to challenges your encounter every day.
              </div>
            </div>
            <div className="quiz-subscription__free">
              {props.store.plan.customPage ? (
                <div className="quiz-subscription__free-title">Try everything NOW</div>
              ) : (
                <div className="quiz-subscription__free-title">Try everything for free</div>
              )}

              <div className="quiz-subscription__free-list">
                <div>Unlimited access to features</div>
                <div>Fast Palm Reading Results</div>
                <div>Daily Horoscope &amp; Biorythms</div>
                <div>100% Ads FREE</div>
              </div>
            </div>
            <div className="quiz-subscription__details">
              <div className="quiz-subscription__details-list">
                {props.store.plan.customPage ? (
                  <div className="tutorial-plan">
                    <div className="parts-row parts-justify parts-md parts-space">
                      <div className="col" style={{ width: '100%' }}>
                        <span className="quiz-subscription__free-title">
                          <span style={{ color: 'white' }}>Special OFFER!</span>
                        </span>
                      </div>
                    </div>
                    <div className="parts-row parts-justify parts-md parts-space">
                      <div className="col" style={{ width: '100%' }}>
                        <span className="quiz-subscription__free-title">
                          <strike class="oldprice">
                            <span style={{ color: 'white', 'font-size': '1.5EM' }}>$29.99 </span>
                          </strike>{' '}
                          <span style={{ color: 'white', padding: '0 0 0 20px' }}>
                            only ${props.store.plan.unitAmount}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="parts-row parts-justify parts-md parts-space">
                      <div className="col" style={{ width: '100%' }}>
                        <span className="quiz-subscription__free-title">
                          <span style={{ color: 'white' }}>NO subscription!</span>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="tutorial-plan">
                    <div className="parts-row parts-justify parts-md parts-space">
                      <div className="col">
                        <span className="tutorial-plan__title">
                          {props.store.plan.trialLength}-{props.store.plan.trialUnit} Trial{' '}
                        </span>
                      </div>
                      <div className="col">
                        <span className="tutorial-plan__title tutorial-plan_price">
                          <strike class="oldprice">
                            <span style={{ color: 'white' }}>9.99</span>
                          </strike>{' '}
                          {props.store.plan.setupFee}$
                        </span>
                      </div>
                    </div>
                    {cid === undefined ? (
                      <div className="parts-row parts-justify parts-md parts-space">
                        <div className="col">
                          <span className="tutorial-plan__title">After trial </span>
                        </div>
                        <div className="col">
                          <span className="tutorial-plan__title  tutorial-plan_price">
                            {props.store.plan.unitAmount}$ / {props.store.plan.intervalUnit}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <div className="parts-row parts-justify parts-md parts-space">
                      <div className="col">
                        <span className="tutorial-plan__title">
                          {' '}
                          <strong>Total Today </strong>
                        </span>
                      </div>
                      <div className="col">
                        <span className="tutorial-plan__title  tutorial-plan_price">
                          <strong>{props.store.plan.setupFee}$</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="quiz-subscription__payment">
            <form onSubmit={handleSubmit} ref={formRef} id="recurly_form">
              <div className="tutorial-payment__field">
                <div className="parts-row parts-4 parts-space-md">
                  <div className="col part-2">
                    <div className="tutorial-payment__label">First Name</div>
                    <div className="tutorial-input tutorial-input--md">
                      <input
                        type="text"
                        data-recurly="first_name"
                        onFocus={() => {
                          removeError('first_name');
                        }}
                      />
                      {errors.first_name ? (
                        <div className="tutorial-form__input__error">First Name {errors.first_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col part-2">
                    <div className="tutorial-payment__label">Last Name</div>
                    <div className="tutorial-input tutorial-input--md">
                      <input
                        type="text"
                        data-recurly="last_name"
                        onFocus={() => {
                          removeError('last_name');
                        }}
                      />
                      {errors.last_name ? (
                        <div className="tutorial-form__input__error">Last Name {errors.last_name}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tutorial-payment__field">
                <div className="tutorial-payment__label">Card number</div>
                <div className="tutorial-input tutorial-input--md">
                  <CardNumberElement
                    className="recurly-field-element"
                    style={{ fontColor: 'white' }}
                    onFocus={() => {
                      removeError('number');
                    }}
                  />
                  {errors.number ? <div className="tutorial-form__input__error">Number {errors.number}</div> : null}
                </div>
              </div>
              <div className="tutorial-payment__field">
                <div className="parts-row parts-4 parts-space-md">
                  <div className="col part-2">
                    <div className="tutorial-payment__label">Month</div>
                    <div className="tutorial-input tutorial-input--md">
                      <CardMonthElement
                        style={{ fontColor: 'white', placeholder: { content: 'MM' } }}
                        className="recurly-field-element"
                        onFocus={() => {
                          removeError('month');
                        }}
                      />
                      {errors.month ? <div className="tutorial-form__input__error">Month {errors.month}</div> : null}
                    </div>
                  </div>
                  <div className="col part-2">
                    <div className="tutorial-payment__label">Year</div>
                    <div className="tutorial-input tutorial-input--md">
                      <CardYearElement
                        style={{ fontColor: 'white', placeholder: { content: 'YY' } }}
                        className="recurly-field-element"
                        onFocus={() => {
                          removeError('year');
                        }}
                      />
                      {errors.year ? <div className="tutorial-form__input__error">Year {errors.year}</div> : null}
                    </div>
                  </div>
                  <div className="col part-2">
                    <div className="tutorial-payment__label">Security Code</div>
                    <div className="tutorial-input tutorial-input--md">
                      <CardCvvElement
                        style={{ fontColor: 'white', placeholder: { content: 'CVV' } }}
                        className="recurly-field-element"
                        onFocus={() => {
                          removeError('cvv');
                        }}
                      />
                      {errors.cvv ? <div className="tutorial-form__input__error">CVV {errors.year}</div> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div ref={sca} id="sca-container"></div>
              {actionTokenId ? (
                <div>
                  <ThreeDSecureAction
                    actionTokenId={actionTokenId}
                    onToken={handleThreeDSecureToken}
                    onError={handleThreeDSecureError}
                    className="recurly-three-d-secure-action"
                  />
                </div>
              ) : null}
            </form>

            {globalError ? <div className="quiz-form__input__error">{globalError}</div> : null}

            <div className="tutorial-payment__field tutorial-payment__field--submit">
              <button className="quiz-button main-btn" id="submit_reg_btn" operational="yes" onClick={handleSubmit}>
                <span id="button_text">Subscribe </span>
                <span id="button_price" className="tutorial-plan_button-price">
                  {' '}
                  {props.store.plan.setupFee.toFixed(2)}$
                </span>
              </button>
            </div>
            {props.store.plan.paypalEnabled ? (
              <button
                className="quiz-button main-btn"
                id="submit_paypal_btn"
                operational="yes"
                style={{ background: '#F5C457' }}
                onClick={handlePaypal}
              >
                <img src={PayPalButton} />
              </button>
            ) : null}
            <div className="tutorial-payment__field tutorial-payment__field--submit">
              <p className="quiz-subscription__footer payment_disclaimer">
                *Your {props.store.plan.trialLength}-{props.store.plan.trialUnit} Trial will last until {formatted}. You
                can cancel anytime before {formatted}. then and will not be charged the full program amount. No
                questions asked, no small print. If you decide {APP_CONSTANTS.APP_NAME} is good for you, on you will be
                charged one payment for ${props.store.plan.unitAmount}&nbsp; for your {props.store.plan.intervalUnit}{' '}
                course. {APP_CONSTANTS.APP_NAME} will automatically charge your card ${props.store.plan.unitAmount}{' '}
                every {props.store.plan.intervalUnit} so you don’t lose access to your account. No refunds or credits
                except if you don’t see results and follow our money-back policy. To cancel, simply let us know by
                email: <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>
                <br />
                <Link
                  target="_blank"
                  to="/privacy"
                  style={{
                    display: 'block',
                    margin: '30px 0 0px',
                    textDecoration: 'underline',
                  }}
                >
                  Privacy policy
                </Link>
                <br />
                <Link
                  target="_blank"
                  to="/terms"
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  Terms of Use
                </Link>{' '}
              </p>

              <div className="quiz-subscription__footer">
                <h4 className="secure_payments_header">Secure payments by</h4>
                <img src={Group41} alt="" />
              </div>
            </div>
          </div>
          <div className="quiz-subscription__footer">
            <p>{APP_CONSTANTS.ADDRESS}</p>
            <p>
              Have a question? <br />
              Reach our friendly support team{' '}
              <a style={{ textDecoration: 'underline' }} href={`mailto:${APP_CONSTANTS.EMAIL}`}>
                here
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  })
);
