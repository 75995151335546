import React from "react";
import IntroBg from '../assets/images/Intro_bgd@3x 1.png';
// import GoogleLogo from '../assets/img/Google_logo.png';
// import FBLogo from '../assets/img/Facebook_logo.png';
// import firebase from "firebase/app";
import "firebase/auth";
// import GoogleLogin from 'react-google-login';
import {inject} from "mobx-react";
import {Processing} from "./processing";
// import {setCookie} from "../helpers/payment/CookieHelper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

@inject('store')
export class Intro extends React.Component {
    // componentDidMount() {
    //     console.log("tracking view ")
    //     fbq('track', 'ViewContent');
    // }


    state = {
        isLoading: false,
        dialog: false,
        message: ""
    }


    // responseGoogle = (response) => {
    //     if (response.isSignedIn) {
    //         this.authUser(firebase.auth().signInWithCredential(new firebase.auth.GoogleAuthProvider.credential(response.tokenId)));
    //     }
    // };

    // responseFacebook = (e) => {
    //     e.preventDefault()
    //     this.authUser(firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider()));
    // };

    goToNextStep = (e) => {
        e.preventDefault()
        this.props.setStep(this.props.step + 1)
    }

    // handleCloseDialog = (e) => {
    //     e.preventDefault()
    //     this.setState({isLoading: false, dialog: false});
    // }

    // authUser = (future) => {
    //     this.setState({isLoading: true});

    //     future.then((result) => {
    //         // const token = result.credential.accessToken;
    //         // console.error(token);
    //         const user = result.user;
    //         // console.log('result', result);
    //         if (result.additionalUserInfo.isNewUser) {
    //             // console.log("new user ..")
    //             this.setState({isLoading: false});
    //             this.props.setFieldValue('name', user.displayName)
    //             // this.props.setFieldValue('email', user.email)
    //             setCookie("userEmail", user.email)
    //             this.props.setFieldValue('uid', user.uid)
    //             this.props.setStep(this.props.step + 1)

    //         } else {
    //             const db = firebase.firestore();
    //             const userRef = db.collection("users").where("uid", "==", user.uid);

    //             userRef.get().then(data => {

    //                 // console.log('data', data);
    //                 // console.log('data empty', data.empty);


    //                 if (data.empty) {
    //                     this.setState({isLoading: false});
    //                     this.props.setFieldValue('name', user.displayName)
    //                     setCookie("userEmail", user.email)
    //                     // this.props.setFieldValue('email', user.email)
    //                     this.props.setFieldValue('uid', user.uid)
    //                     this.props.setStep(this.props.step + 1)

    //                 } else {
    //                     data.forEach(item => {
    //                         // console.log("item", item)
    //                         const id = item.id
    //                         localStorage.setItem('user_id', item.id)
    //                         if (id) {
    //                             if (item.exists) {
    //                                 // console.log("item_data", item.data())
    //                                 console.log("user found", item.data())
    //                                 this.props.store.setPerson({...item.data(), id});
    //                                 this.props.history.push('/');




    //                             } else {
    //                                 console.log("login failed")
    //                                 this.props.store.setLoginFailed()

    //                             }
    //                         } else {
    //                             console.log("login failed")
    //                             this.props.store.setLoginFailed()
    //                         }
    //                         this.setState({isLoading: false});
    //                         setTimeout(() => {
    //                             document.location.href = '/#/';
    //                             window.location.reload();
    //                         }, 50);
    //                     })
    //                 }
    //             }).catch((error) => {
    //                 console.error('ERROR', error)
    //                 this.setState({isLoading: false, dialog: true, message: error.message});
    //             })
    //         }
    //     }).catch((error) => {
    //         console.error('ERR', error)

    //         // Handle Errors here.
    //         var errorCode = error.code;
    //         var errorMessage = error.message;
    //         // The email of the user's account used.
    //         var email = error.email;
    //         // The firebase.auth.AuthCredential type that was used.
    //         var credential = error.credential;
    //         // ...
    //         this.setState({isLoading: false, dialog: true, message: errorMessage});


    //     });
    // }

    render() {

        if (this.state.isLoading) {
            return <Processing/>
        }

        return (
            <div className="quiz-intro">
                <div className="quiz-intro__bg">
                    <img
                        src={IntroBg}
                        alt=""
                    />
                </div>
                <div className="quiz-intro__title h1">
                    Find peace of mind and know yourself better
                </div>
                <div className="quiz-intro__subtitle h3">
                    Feel refreshed, full of energy and ready to the challenge
                </div>
                <button
                    variant="contained"
                    color="primary"
                    onClick={this.goToNextStep}
                    className="quiz-button main-btn"
                >
                    Start the quiz
                </button>
                {/* <div className="quiz-intro__socials">
                    <ul>
                        <li>
                            <GoogleLogin
                                clientId="469862583854-1l8mu1e88kpnbsddpesa3evkc06430tc.apps.googleusercontent.com"
                                render={renderProps => (
                                    <button className="quiz-input__socials__button" onClick={renderProps.onClick}><img
                                        src={GoogleLogo} alt=""
                                    /></button>
                                )}
                                buttonText="Login"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </li>
                        <li>
                            <button type="button" className="quiz-input__socials__button"
                                    onClick={this.responseFacebook}><img
                                src={FBLogo} alt=""/></button>
                        </li>
                    </ul>
                </div> */}

                <Dialog
                    open={this.state.dialog}
                    className="quiz-wrapper"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Something went wrong"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog}>
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }


};
