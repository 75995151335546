import React from 'react';
import { StepButton } from '../components/StepButton';
import Palms1 from '../assets/images/palms1.png';

import { Processing } from './index';
import BackButton from '../components/BackButton';

export class Step7 extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      numberOfErrors: 0,
    };
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.numberOfErrors > 0) {
      this.props.setStep(this.props.step + 1);
    }
  }

  onKeyDown = (keyEvent) => {
    if (!this.props.values.idealWeight && (keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  uploadFile = (event) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.props.setStep(this.props.step + 2);
    }, 5000);

    // const formdata = new FormData();
    // formdata.append("image", event.target.files[0], event.target.files[0].name);

    // fetch(`https://content-admin.appyfurious.com/api/v5/analyzeHand`, {
    //     method: 'POST',
    //     body: formdata
    // })
    //     .then((response) =>
    //         response.json()
    //     )
    //     .then((data) => {
    //         if (data.hand === 'RIGHT') {
    //             this.props.setStep(this.props.step + 2)
    //             this.setState({isLoading: false});
    //         } else if (data.hand === 'LEFT') {
    //             this.setState({
    //                 error: 'Please, use your right hand.',
    //                 isLoading: false,
    //                 numberOfErrors: this.state.numberOfErrors + 1
    //             });
    //         } else {
    //             this.setState({
    //                 error: 'Please, add an image of your hand',
    //                 isLoading: false,
    //                 numberOfErrors: this.state.numberOfErrors + 1
    //             });
    //         }

    //     })
    //     .catch(() => {
    //         // this.setState({
    //         //     error: 'Error, try again',
    //         //     isLoading: false,
    //         //     numberOfErrors: this.state.numberOfErrors + 1
    //         // })

    //         this.props.setStep(this.props.step + 2)
    //         this.setState({isLoading: false});

    //     });
  };

  render() {
    return (
      <div className="quiz-step quiz-palms">
        <div className="quiz-header">
          <BackButton handleBack={this.props.handleBack} />
          <div className="quiz-header__counter">Step 7 of 8</div>
          <div className="quiz-header__title h2">Take a photo of your right palm</div>
        </div>
        <div className="quiz-palms__image">
          <img src={Palms1} alt="" />
        </div>

        <div className="quiz-bottom">
          <StepButton step={this.props.step} errors={this.props.errors} values={this.props.values} />
          <div className="form-group">
            <input
              id="photo"
              name="photo"
              type="file"
              className="form-control"
              accept="image/*"
              onChange={this.uploadFile}
            />
            <div className="quiz-gallery">Choose from gallery</div>
          </div>
          {this.state.error ? <div className="quiz-form__input__error">{this.state.error}</div> : null}
        </div>
        {this.state.isLoading ? <Processing /> : console.log('DOM ready')}
      </div>
    );
  }
}
