import React, { useEffect, useState } from 'react';
import Webcam from 'react-webcam';
// import {Link, Redirect} from 'react-router-dom';
// import {v4 as uuidv4} from "uuid";
import { Processing } from '../forms';
import { inject, observer } from 'mobx-react';
import BackButton from '../components/BackButton';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'environment',
};

export const LeftHandPhoto = inject('store')(
  observer((props) => {
    const webcamRef = React.useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [numberOfErrors, setNumberOfErrors] = useState(0);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
      if (numberOfErrors > 3) {
        setShouldRedirect(true);
      }
    }, [numberOfErrors]);

    const capture = (e) => {
      e.preventDefault();
      if (webcamRef.current.getScreenshot()) {
        setIsLoading(true);
        // fetch(webcamRef.current.getScreenshot())
        //     .then(res => res.blob())
        //     .then(blob => {
        //         const formdata = new FormData();
        //         const file = new File([blob], "filename.jpeg");
        //         formdata.append('image', file, uuidv4())
        //         setIsLoading(true);

        //         fetch(`https://content-admin.appyfurious.com/api/v5/analyzeHand`, {
        //             method: 'POST',
        //             body: formdata
        //         })
        //             .then((response) =>
        //                 response.json()
        //             )
        //             .then((data) => {
        //                 if (data.hand === 'LEFT') {
        //                     setShouldRedirect(true)
        //                     setIsLoading(false)
        //                 } else if (data.hand === 'RIGHT') {
        //                     setNumberOfErrors(num => num + 1)
        //                     setIsLoading(false);
        //                     setError('Please, use your left hand.');
        //                 } else {
        //                     setNumberOfErrors(num => num + 1)
        //                     setIsLoading(false);
        //                     setError('Please, add an image of your hand.');
        //                 }

        //             })
        //             .catch(() => {
        //                 setNumberOfErrors(num => num + 1)
        //                 setIsLoading(false);
        //                 setError('Error, try again.');
        //             });
        //     })
      } else {
        setNumberOfErrors((e) => e + 1);
        setIsLoading(false);
        setError('Error, try again.');
      }
    };

    if (isLoading) {
      return <Processing />;
    }

    if (shouldRedirect) {
      // return <Redirect push to="/right-hand"/>;
      props.setStep((step) => step + 1);
    }

    return (
      <div className="quiz-step quiz-capture">
        <div className="quiz-header">
          <BackButton
            handleBack={() => {
              props.setStep((step) => step - 1);
            }}
          />
          <div className="quiz-header__title h5">We do not collect your biometric data</div>
          {error ? <div className="quiz-form__input__error">{error}</div> : null}
        </div>
        <div className="quiz-capture__image">
          <Webcam
            audio={false}
            height={720}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={videoConstraints}
          />
        </div>
        <button onClick={capture} className="quiz-capture__button">
          Take a photo
        </button>
      </div>
    );
  })
);
