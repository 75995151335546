import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Intro, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8 } from '../forms/index';
// import firebase from 'firebase';
// import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Purchase } from './Purchase';
import { LeftHandPhoto } from './LeftHandPhoto';
import { RightHandPhoto } from './RightHandPhoto';

const renderStep = (step, values, errors, touched, setFieldValue, setStep, history, handleBack) => {
  switch (step) {
    case 1:
      return (
        <Intro
          errors={errors}
          touched={touched}
          step={step}
          values={values}
          setFieldValue={setFieldValue}
          setStep={setStep}
          history={history}
        />
      );
    case 2:
      return <Step1 errors={errors} touched={touched} step={step} values={values} handleBack={handleBack} />;
    case 3:
      return <Step2 errors={errors} touched={touched} step={step} values={values} handleBack={handleBack} />;
    case 4:
      return <Step3 errors={errors} touched={touched} step={step} values={values} handleBack={handleBack} />;
    case 5:
      return <Step4 errors={errors} touched={touched} step={step} values={values} handleBack={handleBack} />;
    case 6:
      return <Step5 errors={errors} touched={touched} step={step} values={values} handleBack={handleBack} />;
    case 7:
      return (
        <Step6
          errors={errors}
          touched={touched}
          step={step}
          values={values}
          setStep={setStep}
          setFieldValue={setFieldValue}
          handleBack={handleBack}
        />
      );
    case 8:
      return (
        <LeftHandPhoto
          errors={errors}
          touched={touched}
          step={step}
          values={values}
          setStep={setStep}
          setFieldValue={setFieldValue}
        />
      );
    case 9:
      return (
        <Step7
          errors={errors}
          touched={touched}
          step={step}
          values={values}
          setStep={setStep}
          setFieldValue={setFieldValue}
          handleBack={handleBack}
        />
      );
    case 10:
      return (
        <RightHandPhoto
          errors={errors}
          touched={touched}
          step={step}
          values={values}
          setStep={setStep}
          setFieldValue={setFieldValue}
        />
      );
    case 11:
      return <Step8 errors={errors} touched={touched} step={step} values={values} handleBack={handleBack} />;
    case 12:
      return <Purchase></Purchase>;
    default:
      return <Intro errors={errors} touched={touched} step={step} values={values} />;
  }
};

export const Stage1 = (props) => {
  const location = useLocation();
  const [step, setStep] = useState(1);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const preParam = searchParams.get('pre');
    if (preParam === 'false') {
      setStep(2);
    }
  }, []);

  const formData = {
    name: '',
    gender: '',
    dayOfBirth: '',
    monthOfBirth: '',
    yearOfBirth: '',
    hourOfBirth: '',
    minuteOfBirth: '',
    timeFormat: 'pm',
    maritalStatus: '',
    email: '',
    uid: '',
    photo: null,
    zodiac: '',
  };
  const handleSubmit = () => {
    if (step !== 1) {
      setStep((s) => {
        return s + 1;
      });
    }
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    switch (step) {
      case 9: {
        setStep((s) => s - 2);
        break;
      }
      case 11: {
        setStep((s) => s - 2);
        break;
      }
      default: {
        setStep((s) => s - 1);
      }
    }

    window.scrollTo(0, 0);
  };

  const validate = (values) => {
    // if (values.email) {
    //   const db = firebase.firestore();

    //   return db
    //     .collection('users')
    //     .where('email', '==', values.email)
    //     .get()
    //     .then((r) => {
    //       if (!r.empty) {
    //         return { email: 'Email exists' };
    //       }
    //     })
    //     .catch((reason) => {
    //       console.error(reason);
    //     });
    // }
    const errors = {};
    return errors;
  };

  return (
    <>
      <Formik enableReinitialize initialValues={{ ...formData }} onSubmit={handleSubmit} validate={validate}>
        {({ values, errors, touched, setFieldValue }) => (
          <div className="quiz-wrapper">
            <div className="quiz-wrapper__inner">
              <Form className="quiz-form">
                {renderStep(step, values, errors, touched, setFieldValue, setStep, props.history, handleBack)}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
