export const APP_CONSTANTS = {
  APP_NAME: '__APP_NAME__',
  ADDRESS: '__ADDRESS__',
  EMAIL: 'support@astrolegends.online',
};

export const DEFAULT_PLAN = {
  trialLength: 7,
  trialUnit: 'days',
  intervalLength: 1,
  intervalUnit: 'month',
  setupFee: 1,
  unitAmount: 14.99,
};
