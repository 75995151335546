import React, { useState } from 'react';
import { StepButton } from '../components/StepButton';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import BackButton from '../components/BackButton';

export class Step1 extends React.Component {
  state = {
    checked: true,
  };

  onKeyDown = (keyEvent) => {
    if (!this.props.values.name && (keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  render() {
    const searchParams = new URLSearchParams(location.search);
    const isWithIntro = searchParams.get('pre') === 'false';

    return (
      <div className="quiz-step">
        <div className="quiz-header">
          {!isWithIntro && <BackButton handleBack={this.props.handleBack} />}
          <div className="quiz-header__counter">Step 1 of 8</div>
          <div className="quiz-header__title h2">What’s your name?</div>
        </div>
        <div className="quiz-input quiz-input__name">
          <Field
            name="name"
            as={TextField}
            placeholder="Your name here"
            type="text"
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = 'Your name here')}
            className="quiz-form__input"
            autoComplete="off"
            onKeyDown={this.onKeyDown}
          />
        </div>
        <div className="quiz-form__checkbox_wrapper">
          <input
            type="checkbox"
            checked={this.state.checked}
            onClick={() => {
              this.setState({
                checked: !this.state.checked,
              });
            }}
          ></input>
          <p>
            By continuing, I agree to <br />{' '}
            <Link target="_blank" to="/terms">
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link target="_blank" to="/privacy">
              Privacy Policy.
            </Link>
          </p>
        </div>
        <StepButton
          disabled={!this.state.checked}
          step={this.props.step}
          errors={this.props.errors}
          values={this.props.values}
        />
      </div>
    );
  }
}
