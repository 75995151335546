'use strict';

export default class Constants {
    static get tutorialStateCookie() {
        return '__tutorial_checkout';
    }

    static get planStateCookie() {
        return '__plan_checkout';
    }

    static get reactStateCookie() {
        return '__state';
    }

    static get activeClass() {
        return 'fit-active';
    }

    static get invalidClass() {
        return 'is-invalid';
    }

    static get kg() {
        return 'kg';
    }

    static get lbs() {
        return 'lbs';
    }

    static get cm() {
        return 'cm';
    }

    static get ftIn() {
        return 'ft + in';
    }
}
