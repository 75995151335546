import React, {useState} from "react";
import {Form, Formik} from "formik";
import {Profile, Subscription} from '../forms/index';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const renderStep = (step, values, errors, touched, setFieldValue) => {
    switch (step) {
        case 1:
            return <Subscription errors={errors} touched={touched} step={step} values={values}
                                 setFieldValue={setFieldValue}/>;
        case 2:
            return <Profile errors={errors} touched={touched} step={step} values={values}
                            setFieldValue={setFieldValue}/>;
        default:
            return <Subscription errors={errors} touched={touched} step={step} values={values}
                                 setFieldValue={setFieldValue}/>;
    }
};

export const Purchase = () => {
    const [step, setStep] = useState(9);
    const classes = useStyles();
    const formData = {
        firstName: "Konstantinus",
        gender: "male",
        dayOfBirth: "20",
        monthOfBirth: "9",
        yearOfBirth: "1985",
        hourOfBirth: "12",
        minuteOfBirth: "00",
        timeFormat: "am",
        maritalStatus: "Single",
        email: "test@gmail.com",
        photo: null,
    };
    const handleSubmit = () => {
        setStep(step => step + 1);
        window.scrollTo(0, 0)
    };

    const validate = values => {
        const errors = {};
        return errors;
    };
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{...formData}}
                onSubmit={handleSubmit}
                validate={validate}
            >
                {({values, errors, touched, setFieldValue}) => (
                    <div className="quiz-wrapper">
                        <div className="quiz-wrapper__inner">
                            <Form className="">
                                {renderStep(step, values, errors, touched, setFieldValue)}
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};
