import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { observable } from 'mobx';
import observablePersonStore from './stores/user-store';

import { Stage1 } from './pages/Stage-1';
import { TermsOfUse } from './pages/TermsOfUse';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
// import {EmailConfirmation} from './pages/EmailConfirmarion';
// import {UnsubscribeConfirmation} from './pages/UnsubscribeConfirmarion';
import { Purchase } from './pages/Purchase';
import { Stage2 } from './pages/Stage-2';
import { HomePage } from './pages/HomePage';
import { Profile } from './pages/Profile';
import { Biorhythm } from './pages/Biorhythm';
import { Horoscope } from './pages/Horoscope';
import { PalmReading } from './pages/PalmReading';
import { Compatibility } from './pages/Compatibility';
import { CompatibilitySelect } from './pages/CompatibilitySelect';
import { PalmReadingLeftHand } from './pages/PalmReadingLeftHand';
import { LeftHandPhoto } from './pages/LeftHandPhoto';
import { PalmReadingRightHand } from './pages/PalmReadingRightHand';
import { RightHandPhoto } from './pages/RightHandPhoto';
// import firebase from "firebase";
import { Processing } from './forms';
import { observer, Provider } from 'mobx-react';
// import {config} from "./components/firebase_config";
// import queryString from "query-string";
// import {getCookie, setCookie} from "./helpers/payment/CookieHelper";
// import {UnsubscribeInner} from "./pages/Unsubscribe";
import { UnsubscribePage } from './pages/UnsubscribePage';
// import {isEmptyArray} from "formik";

export const PrivateRoute = ({ isAuthenticated, children, ...rest }) => (
  <Route {...rest}>{isAuthenticated ? children : <Redirect to="/purchase" />}</Route>
);

@observer
class App extends Component {
  state = {
    loading: true,
    person: null,
    isSubs: false,
  };

  constructor(props) {
    super(props);
    this.personStore = observable(observablePersonStore);
  }

  componentDidMount() {
    // console.log("QUERY")
    // console.log(">> " + window.location.search)
    // const values = queryString.parse(window.location.search);
    // setCookie("camp_id", values.campaign_id)
    // setCookie("camp_name", values.campaign_name)
    // setCookie("adset_id", values.adset_id)
    // setCookie("adset_name", values.adset_name)
    // setCookie("ad_id", values.ad_id)
    // setCookie("ad_name", values.ad_name)
    // setCookie("country_code", values.country_code)
    // setCookie("offer", values.offer)

    // const db = firebase.initializeApp(config).firestore();

    const id = localStorage.getItem('user_id');

    // TODO: Check subscription?
    if (id) {
      // const userRef = db.collection('users').doc(id);
      // userRef.get().then(ref => {
      //     if (ref.exists) {
      //         // TODO redirect to subscribtion? !ref.data().finished_stage_2
      //         const requestOptions = {
      //             method: 'POST',
      //             headers: {'Content-Type': 'application/json', 'X-Appy-Key': '3lPWXo2VIJmK'},
      //             body: JSON.stringify({email: ref.data()['email']})
      //         };
      //         let isSub = false;
      //         fetch('https://us-central1-new-live-palm-reader.cloudfunctions.net/isSub', requestOptions).then((response) => {
      //             return response.json();
      //         }).then((data) => {
      //             if (data.empty) {
      //                 isSub = false
      //             } else {
      //                 isSub = data.subs;
      //             }
      //             console.log("Sub: " + isSub)
      //             this.personStore.setPerson({...ref.data(), id}, isSub);
      //             this.setState({loading: false, person: {...ref.data(), id}, subs: isSub})
      //         });
      //     } else {
      //         this.personStore.setLoginFailed()
      //         this.setState({loading: false})
      //     }
      // });
    } else {
      this.personStore.setLoginFailed();
      this.setState({ loading: false });
    }
  }

  render() {
    console.log('render ');
    const store = this.personStore;
    if (this.state.loading) {
      console.log('render login false');
      return <Processing />;
    }

    console.log('render normal ' + this.state.subs);
    console.log('render person ' + this.state.person);
    return (
      <Provider store={this.personStore}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute isAuthenticated={this.state.subs} path="/home">
              <HomePage />
            </PrivateRoute>

            <Route exact path="/" render={(props) => <Stage1 person={this.state.person} {...props} />} />
            <Route path="/stage-2" render={(props) => <Stage2 person={store.person} {...props} />} />
            <PrivateRoute isAuthenticated={store.isLoggedIn} path="/profile">
              <Profile person={this.state.person} />
            </PrivateRoute>
            <PrivateRoute isAuthenticated={this.state.subs} path="/biorhythm">
              <Biorhythm person={this.state.person} />
            </PrivateRoute>
            <PrivateRoute isAuthenticated={this.state.subs} path="/horoscope">
              <Horoscope person={this.state.person} />
            </PrivateRoute>
            <PrivateRoute isAuthenticated={this.state.subs} path="/palm-reading">
              <PalmReading />
            </PrivateRoute>
            <Route path="/compatibility" component={Compatibility}></Route>
            <PrivateRoute isAuthenticated={this.state.subs} path="/compatibility-select">
              <CompatibilitySelect person={this.state.person} />
            </PrivateRoute>
            <Route path="/left-hand">
              <PalmReadingLeftHand />
            </Route>
            <Route path="/left-hand-photo" component={LeftHandPhoto}></Route>
            <Route path="/right-hand">
              <PalmReadingRightHand />
            </Route>
            <Route path="/right-hand-photo" component={RightHandPhoto}></Route>
            {/* <Route path="/email-confirm" component={EmailConfirmation}>
                            </Route> */}
            <Route path="/purchase" component={Purchase}></Route>
            <Route path="/unsubscribe" component={UnsubscribePage}></Route>
            {/* <Route path="/unsubscribe-confirm" component={UnsubscribeConfirmation}>
                            </Route> */}

            <Route path="/terms" component={TermsOfUse} />
            <Route path="/privacy" component={PrivacyPolicy} />

            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}
export default App;
