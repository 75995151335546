import React from 'react';
import { StepButton } from '../components/StepButton';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { getCookie } from '../helpers/payment/CookieHelper';
import BackButton from '../components/BackButton';

export class Step8 extends React.Component {
  onKeyDown = (keyEvent) => {
    if (!this.props.values.email && (keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  componentDidMount() {
    // console.log("setting input from cookie " + getCookie("userEmail"))
    setTimeout(() => {
      this.props.values.email = getCookie('userEmail');
      jQuery('#email_input').val(getCookie('userEmail'));
    }, 50);
  }

  render() {
    return (
      <div className="quiz-step">
        <div className="quiz-header">
          <BackButton handleBack={this.props.handleBack} />
          <div className="quiz-header__counter">Step 8 of 8</div>
          <div className="quiz-header__title h2">What’s your email?</div>
        </div>
        <div className="quiz-input">
          <Field
            name="email"
            id="email_input"
            as={TextField}
            placeholder="Your email here"
            type="email"
            className="quiz-form__input"
            onFocus={(e) => {
              if (!e.target.value) {
                e.target.placeholder = '';
              }
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                e.target.placeholder = 'Your email here';
              }
            }}
            autoComplete="off"
            onKeyDown={this.onKeyDown}
          />
          {this.props.errors.email && this.props.touched.email ? (
            <div className="quiz-form__input__error">{this.props.errors.email}</div>
          ) : null}
        </div>
        <StepButton step={this.props.step} errors={this.props.errors} values={this.props.values} />
      </div>
    );
  }
}
