import React from "react";
import {StepButton} from "../components/StepButton";
import Mail from '../assets/images/mail.png';
import Mail2x from '../assets/images/mail@2x.png';
import firebase from "firebase/app";
import {v4 as uuidv4} from 'uuid';
import {getZodiac2} from "../helpers/zodiac";
import {Processing} from "./processing";
import {Navigate} from "react-router";
import {inject} from "mobx-react";

@inject('store')
export class Confirmation extends React.Component {
    state = {
        isLoading: false,
        redirect: false
    }

    componentDidMount() {
        this.setState({isLoading: true})
        const uuid = uuidv4();
        this.props.values["email_confirm_uid"] = uuid;
        this.props.values["email_confirmed"] = false;
        this.props.values["finished_stage_1"] = true;
        this.props.values["finished_stage_2"] = false;
        this.props.values.zodiac = getZodiac2(Number(this.props.values.monthOfBirth), Number(this.props.values.dayOfBirth));

        const db = firebase.firestore();

        db.collection('users').add(this.props.values).then(r => {
            console.log(r);

            const dateBirth = this.props.values.dayOfBirth + "-" + this.props.values.monthOfBirth + "-" + this.props.values.yearOfBirth

            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({uuid: uuid, baseUrl: `${window.location.origin.toString()}/#/`})
            };

            fbq('track', 'AddToWishlist', {
                birth: dateBirth,
                gender: this.props.values.gender,
                name: this.props.values.name
            });

            fetch('https://us-central1-new-live-palm-reader.cloudfunctions.net/sendMail', requestOptions).finally(() => {
                this.setState({isLoading: false})
                // this.id = setTimeout(() => this.setState({redirect: true}), 1000)
                const db = firebase.firestore();
                this.future = db.collection("users").where("email_confirm_uid", "==", uuid).get().then(value => {

                    value.forEach(doc => {
                        // console.log(doc.id);
                        const userRef = db.collection('users').doc(doc.id);
                        userRef.get().then(data => {
                            // console.log("setting store person")
                            this.props.store.setPerson({...data.data(), id: doc.id})
                            localStorage.setItem('user_id', doc.id)
                            // console.log("setting store person DONE")
                            this.setState({redirect: true})
                        });
                    });
                });
            });
        }).catch(reason => {
            this.setState({isLoading: false})
        });
    }

    render() {

        if (this.state.isLoading) {
            return <Processing/>
        }

        if (this.state.redirect) {
            return <Navigate to="/stage-2"/>
        }

        // return (
        //
        //     <div className="quiz-confirmation">
        //         <div className="quiz-confirmation__bg">
        //             <img src={Mail} srcSet={Mail2x + " 2x"} alt=""/>
        //         </div>
        //         <div className="quiz-confirmation__title h2">
        //             Confirm your email address to continue
        //         </div>
        //         <div className="quiz-confirmation__subtitle h4">
        //             We’ve sent a confirmation link on your email address. Please open you email application to confirm
        //             your email.
        //         </div>
        //         <StepButton step={this.props.step} errors={this.props.errors} values={this.props.values}/>
        //
        //     </div>
        // );

        return <Processing/>
    }
};
