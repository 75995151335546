import React from "react";
import {StepButton} from "../components/StepButton";
import {Field} from "formik";
import TextField from "@material-ui/core/TextField";

export class UnsubscribeInner extends React.Component {
    onKeyDown = (keyEvent) => {
        if (!(this.props.values.email) && (keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    render() {

        return (
            <div className="quiz-step">
                <div className="quiz-header">
                    <div className="quiz-header__counter">
                        Unsubscribe
                    </div>
                    <div className="quiz-header__title h2">
                        Please enter your email to cancel subscription
                    </div>
                </div>
                <div className="quiz-input">
                    <Field
                        name="email"
                        as={TextField}
                        placeholder="Your email here"
                        type="email"
                        className="quiz-form__input"
                        onFocus={(e) => e.target.placeholder = ""}
                        onBlur={(e) => e.target.placeholder = "Your email here"}
                        autoComplete="off"
                        onKeyDown={this.onKeyDown}
                    />
                    {this.props.errors.email && this.props.touched.email ? (
                        <div className="quiz-form__input__error">{this.props.errors.email}</div>
                    ) : null}
                </div>
                <button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={"quiz-button main-btn "}>
                    Continue
                </button>
            </div>
        );
    }
};
