import {observable} from 'mobx';
import {getZodiac2} from "../helpers/zodiac";

class ObservablePersonStore {
    @observable isLoggedIn = null;
    @observable isSub = null;

    @observable plan = {
        id: '',
        trialLength: -1,
        trialUnit: '',
        intervalLength: -1,
        intervalUnit: '',
        setupFee: -1,
        unitAmount: -1,
        paypalEnabled: false,
        customPage: false
    };

    @observable person = {
        id: '',
        name: '',
        zodiac: '',
        gender: '',
        dateOfBirth: {
            day: '',
            month: '',
            year: ''
        },
        timeOfBirth: {
            hour: '',
            minute: '',
            format: ''
        },
        maritalStatus: '',
        email: '',
        uid: ''
    };

    constructor() {
    }

    setLoginFailed() {
        this.isLoggedIn = false;
    }

    setPerson(p, sub) {
        this.person.id = p.id;
        this.isLoggedIn = true;
        this.isSub = sub
        this.person.name = p.name
        this.person.gender = p.gender
        this.person.dateOfBirth = {
            day: p.dayOfBirth,
            month: p.monthOfBirth,
            year: p.yearOfBirth
        }
        this.person.timeOfBirth = {
            hour: p.hourOfBirth,
            minute: p.minuteOfBirth,
            format: p.timeFormat
        }
        this.person.maritalStatus = p.maritalStatus
        this.person.email = p.email
        this.person.uid = p.uid
        this.person.zodiac = p.zodiac
    }

    setPerson(p) {
        this.person.id = p.id;
        this.isLoggedIn = true;
        this.person.name = p.name
        this.person.gender = p.gender
        this.person.dateOfBirth = {
            day: p.dayOfBirth,
            month: p.monthOfBirth,
            year: p.yearOfBirth
        }
        this.person.timeOfBirth = {
            hour: p.hourOfBirth,
            minute: p.minuteOfBirth,
            format: p.timeFormat
        }
        this.person.maritalStatus = p.maritalStatus
        this.person.email = p.email
        this.person.uid = p.uid
        this.person.zodiac = p.zodiac
    }

    setPlan(plan, code) {
        this.plan.id = code;
        this.plan.trialLength = plan.trialLength;
        this.plan.trialUnit = plan.trialUnit;
        this.plan.intervalLength = plan.intervalLength;
        this.plan.intervalUnit = plan.intervalUnit;
        this.plan.setupFee = plan.setupFee;
        this.plan.unitAmount = plan.unitAmount;
        this.plan.paypalEnabled = plan.paypal_enabled;
        this.plan.customPage = plan.custom_page;
    }

}

const observablePersonStore = new ObservablePersonStore();

export default observablePersonStore;
