import React from 'react';
import { getCookie } from '../helpers/payment/CookieHelper';

export const StepButton = (props) => {
  const { step, values, errors, disabled } = props;

  switch (step) {
    case 2:
      return (
        <>
          <button
            variant="contained"
            color="primary"
            type="submit"
            className={'quiz-button main-btn ' + (!values.name || disabled ? 'quiz-button__disabled' : '')}
          >
            Continue
          </button>
        </>
      );
    case 3:
      return (
        <>
          <button
            variant="contained"
            color="primary"
            type="submit"
            className={'quiz-button main-btn ' + (!values.gender ? 'quiz-button__disabled' : '')}
          >
            Continue
          </button>
        </>
      );
    case 4:
      return (
        <>
          <button
            variant="contained"
            color="primary"
            type="submit"
            className={
              'quiz-button main-btn ' +
              (!values.dayOfBirth || !values.monthOfBirth || !values.yearOfBirth ? 'quiz-button__disabled' : '')
            }
          >
            Continue
          </button>
        </>
      );
    case 5:
      return (
        <div>
          <button
            variant="contained"
            color="primary"
            type="submit"
            className={'quiz-button main-btn '}
            style={{ marginBottom: '20px' }}
          >
            Skip
          </button>
          <button
            variant="contained"
            color="primary"
            type="submit"
            className={
              'quiz-button main-btn ' + (!values.hourOfBirth || !values.minuteOfBirth ? 'quiz-button__disabled' : '')
            }
          >
            Continue
          </button>
        </div>
      );
    case 6:
      return (
        <>
          <button
            variant="contained"
            color="primary"
            type="submit"
            className={'quiz-button main-btn ' + (!values.maritalStatus ? 'quiz-button__disabled' : '')}
          >
            Continue
          </button>
        </>
      );
    case 7:
      return (
        <>
          <button variant="contained" color="primary" type="submit" className="quiz-button main-btn">
            Take a photo
          </button>
        </>
      );
    case 9:
      return (
        <>
          <button variant="contained" color="primary" type="submit" className="quiz-button main-btn">
            Take a photo
          </button>
        </>
      );
    case 11:
      return (
        <>
          <button variant="contained" color="primary" type="submit" className="quiz-button main-btn">
            Continue
          </button>
        </>
      );
    default:
      return (
        <>
          <button variant="contained" color="primary" type="submit" className="quiz-button main-btn">
            Continue
          </button>
        </>
      );
  }
};
