'use strict';

function getValueFromCookiePart(cookiePart, name) {
    if (0 === cookiePart.trim().indexOf(name + '=')) {
        return cookiePart.trim().substr(name.length + 1).trim();
    }
    return undefined;
}

export function getCookie(name) {
    let result = undefined;
    document.cookie.split(';').forEach(
        cookiePart => {
            const value = getValueFromCookiePart(cookiePart, name);
            if (undefined !== value) {
                result = decodeURIComponent(value);
            }
        }
    );
    return result;
}

export function setCookie(name, value) {
    if (undefined === value) {
        document.cookie = name + '=; Max-Age=-99999999; path=/';
    } else {
        document.cookie = name + '=' + encodeURIComponent(value) + '; path=/';
    }
}
