import React from 'react';
import BackArrow from '../assets/images/back-arrow.svg';

export default class BackButton extends React.Component {
  render() {
    return (
      <div className="quiz-header__back-button" onClick={this.props.handleBack}>
        <img src={BackArrow} alt="" />
      </div>
    );
  }
}
