import React from 'react';
import { StepButton } from '../components/StepButton';
import { Field } from 'formik';
import BackButton from '../components/BackButton';

export class Step2 extends React.Component {
  render() {
    return (
      <div className="quiz-step">
        <div className="quiz-header">
          <BackButton handleBack={this.props.handleBack} />
          <div className="quiz-header__counter">Step 2 of 8</div>
          <div className="quiz-header__title h2">What’s your gender?</div>
        </div>
        <div className="quiz-input">
          <div className="quiz-input__gender">
            <Field
              name="gender"
              render={({ field }) => (
                <>
                  <label className="tutorial-quiz__label tutorial-quiz__label--link">
                    <input
                      {...field}
                      id="male"
                      value="male"
                      checked={field.value === 'male'}
                      name="gender"
                      type="radio"
                      onClick={this.handleClick}
                    />
                    <span className="tutorial-quiz__text">Male</span>
                  </label>

                  <label className="tutorial-quiz__label tutorial-quiz__label--link">
                    <input
                      {...field}
                      id="female"
                      value="female"
                      name="gender"
                      checked={field.value === 'female'}
                      type="radio"
                      onClick={this.handleClick}
                    />
                    <span className="tutorial-quiz__text">Female</span>
                  </label>

                  <label className="tutorial-quiz__label tutorial-quiz__label--link">
                    <input
                      {...field}
                      id="non-binary"
                      value="non-binary"
                      name="gender"
                      checked={field.value === 'non-binary'}
                      type="radio"
                      onClick={this.handleClick}
                    />
                    <span className="tutorial-quiz__text">Non-binary</span>
                  </label>
                </>
              )}
            />
          </div>
        </div>
        <StepButton step={this.props.step} errors={this.props.errors} values={this.props.values} />
      </div>
    );
  }
}
