import React, {useState} from "react";
import {Form, Formik} from "formik";

import {makeStyles} from "@material-ui/core/styles";
import {UnsubscribeInner} from "./Unsubscribe";
import {v4 as uuidv4} from "uuid";
import {Processing} from "../forms";
import Mail from "../assets/images/mail.png";
import Mail2x from "../assets/images/mail@2x.png";
import {StepButton} from "../components/StepButton";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
}));


export const UnsubscribePage = () => {

    const [state, setState] = useState({
        isLoading: false,
        hasConfirmed: false,
        startPage: true
    });


    const classes = useStyles();
    const formData = {
        email: ""
    }
    const handleSubmit = (values) => {
        // console.log("Handle submit")
        // console.log(values)

        setState({isLoading: true, startPage: false, hasConfirmed: false});
        const uuid = uuidv4();
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({uuid: uuid, baseUrl: `${window.location.origin.toString()}/#/`, email: values.email})
        };

        // console.log("call function")
        fetch('https://us-central1-new-live-palm-reader.cloudfunctions.net/unsubscribe', requestOptions).finally(() => {
            // console.log("call finish")
            setState({isLoading: false, startPage: false, hasConfirmed: true});
        });




    };

    const validate = values => {
        const errors = {};
        return errors;
    };

    return (
        <div>
            {state.isLoading ? <Processing/> : console.log("DOM Ready")}

            {(state.hasConfirmed) &&
            <div>
                {/*<Redirect to={"/stage-2"}/>*/}
                <div className="quiz-wrapper">
                    <div className="quiz-wrapper__inner">
                        <form className="quiz-form">
                <div className="quiz-confirmation">
                    <div className="quiz-confirmation__bg">
                        <img src={Mail} srcSet={Mail2x + " 2x"} alt=""/>
                    </div>
                    <div className="quiz-confirmation__title h2">
                        Confirm your email address to cancel your subscription
                    </div>
                    <div className="quiz-confirmation__subtitle h4">
                        We’ve sent a confirmation link on your email address. Please open you email application and follow
                        the instructions
                    </div>
                </div>
                        </form></div></div>
            </div>
            }
            {(state.startPage) &&
            <div>
                <>
                    <Formik
                        enableReinitialize
                        onSubmit={handleSubmit}
                        validate={validate}
                        initialValues={{...formData}}
                    >
                        {({values, errors, touched, setFieldValue}) => (
                            <div className="quiz-wrapper">
                                <div className="quiz-wrapper__inner">
                                    <Form className="quiz-form">
                                        <UnsubscribeInner errors={errors} touched={touched} values={values}/>
                                    </Form>
                                </div>
                            </div>
                        )}
                    </Formik>
                </>
            </div>
            }

        </div>
    );
};
