import React from "react";

import {Elements, RecurlyProvider} from '@recurly/react-recurly';
import {SubScriptionInner} from "./subscriptionInner";

export const Subscription = (props) => {
    return (
        <RecurlyProvider publicKey="ewr1-TZAIwcPilISuHDCPr2X84I">
            <Elements>
                <SubScriptionInner/>
            </Elements>
        </RecurlyProvider>
    );
}



