import React from 'react';
import { APP_CONSTANTS } from '../constants/app';

export const TermsOfUse = () => {
  return (
    <div className="terms__wrapper">
      <p className="terms__header">END USER LICENSE AGREEMENT</p>
      <p className="terms__date">Date: October 15, 2018</p>
      <div className="terms__body">
        <p>
          This End User License Agreement is a valid agreement between You and the Company. Please, read this EULA
          carefully. The rules of the EULA are binding upon You. If You do not agree to be bound by the rules of the
          EULA, please, do not install the Application or delete it from the Device, if You have already installed it.
          If You continue to use the Application, this will mean that You have read and understood the EULA and agree to
          be bound by it.
        </p>
        <li>
          <span className="terms__main-list">DEFINITIONS AND TERMS</span>
          <p>
            In order to understand the rules of the EULA clearly you shall familiarize yourself with the meaning of
            terms used in the EULA. In this EULA the following terms, when capitalized, shall have the following
            meanings:
          </p>
          <ol className="terms__secondary">
            <li>
              “Account” – means a personal account of a user of the Platform services, which is provided by the Platform
              Administration under the Platform Terms of Use or similar document. The Account is accessed with a
              Platform ID (account name) and a password.
            </li>
            <li>
              “App Store” – means a virtual store (platform) managed by Apple for distribution of applications for
              devices running iOS operating system.
            </li>
            <li>
              “Apple” – means Apple, Inc., a company incorporated in California, the United States of America, any
              parent or group company or any successor thereof that manages App Store.
            </li>
            <li>
              “Application”, “App” – means an application for a mobile device that We make available on the Platform, as
              well as any services provided through the Application.
            </li>
            <li>
              “Company”, “We” – means {APP_CONSTANTS.APP_NAME}, a company incorporated in the United States of America
              at the following address: {APP_CONSTANTS.ADDRESS}.
            </li>
            <li>
              “Content” – means all kinds of content, including, among other, data, texts, images, videos, sounds,
              links, software and other pieces of information in any form, which are made available to You through the
              App.
            </li>
            <li>
              “Device” – means a smartphone, a tablet or another similar mobile device that You either own or otherwise
              legally control exclusively for Your personal non-commercial use.
            </li>
            <li>
              “EULA” – means this End User License Agreement, which is a binding agreement between You and the Company.
              The EULA may incorporate other documents, such as Privacy Policy that regulate relations between You and
              Us with regard to using the Application. If a document represents a part of the EULA, it has a clear
              indication thereof.
            </li>
            <li>
              “Google” – means Google Inc, registered in Delaware, USA with the headquarters located at the address:
              1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Google Ireland Limited registered in Ireland with
              the headquarters located at the address: Gordon House, Barrow Street, Dublin 4, Ireland; Google Commerce
              Limited registered in Ireland with the headquarters located at the address: Gordon House, Barrow Street,
              Dublin 4; as well as Google Asia Pacific Pte. Limited registered in Singapore with the headquarters
              located at the address: 8 Marina View, Asia Square 1 #30-01, Singapore 018960.
            </li>
            <li>
              “Google Play” – means a virtual store (platform) managed by Google for distribution of applications for
              devices running Android operating system.
            </li>
            <li>
              “Intellectual Property Rights”&nbsp;– means, collectively, rights under any patent, trademark, copyright
              and trade secret laws, and any other intellectual property or proprietary rights recognized in any country
              or jurisdiction worldwide, including, without limitation, moral or similar rights.
            </li>
            <li>
              “Platform” – Google Play (for users who use the Application on a Device running Android operating system)
              or App Store (for users who use the Application on a Device running iOS operating system).{' '}
            </li>
            <li>
              “Platform Administrator” – Google (for Google Play virtual store) and Apple (for App Store virtual store).
            </li>
            <li>
              Subscription” – means a period, during which You use the App as a whole or certain additional Content for
              a fee.
            </li>
            <li>
              “User-generated Content” – means any form of Content, including, among other, data, texts, images, videos,
              sounds, links, that have been posted by users to online platforms, for example, social networks (e.g.
              Instagram, Facebook).{' '}
            </li>
            <li>“You” – means an individual using the App.</li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">LEGAL CAPACITY AND AGE</span>
          <ol className="terms__secondary">
            <li>
              The Application is designed for users over 16. If You install and/or use the App, You confirm that You are
              at least 16 years old and have full legal capacity to enter into the Agreement.
            </li>
            <li>
              If You are between 16 and 18 years old, Your parent, guardian or other representative of legal age has to
              check and agree to this EULA on Your behalf. If You install and/or use the App, You confirm that Your
              parent, guardian or other representative of legal age has agreed to the terms of this EULA and permits You
              to use the App.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">CHANGES OF THE EULA</span>
          <ol className="terms__secondary">
            <li>
              We may introduce changes to this EULA from time to time. You shall regularly check the latest version of
              the EULA at the link, available on the web-page of the App on the Platform.{' '}
            </li>
            <li>
              If You do not agree with the terms of the updated EULA, You are not allowed to use the Application
              starting from the day, on which the updated EULA takes effect. In this case You shall delete the App from
              all Your Devices and cancel renewal of your Subscriptions.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">DEVICE</span>
          <ol className="terms__secondary">
            <li>
              If You intend to download onto and/or run the App on any Device not owned by You, You must have the
              owner’s permission to do so. You will be responsible for complying with this EULA whether or not You own
              the Device.
            </li>
            <li>
              If You do not own the Device, You are allowed to install and/or use the App only through Your Account.{' '}
            </li>
            <li>
              If You use the Device together with other people, please, make sure that nobody except You can use the
              Application from Your Account. All actions taken in Your Account are considered Your actions in disregard
              of who actually takes such actions. You are fully responsible for all actions taken in Your Account.
            </li>
            <li>
              If You decide to sell or otherwise dispose of the Device, You shall delete the Application from the
              Device.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">INTELLECTUAL PROPERTY</span>
          <ol className="terms__secondary">
            <li>
              We hereby grant You a non-exclusive, personal, limited, revocable and non-transferable license to access,
              download and run the App only on Your Device, provided that You agree to comply with all the terms and
              conditions of this EULA. Please, take into consideration that the App is licensed, not sold, to You.
            </li>
            <li>
              The Company or the licensor of the Company is the only holder of Intellectual Property Rights with regard
              to the Application. The Company retains all rights, title and interest in and to the App and reserves all
              rights not expressly granted to You in this EULA.
            </li>
            <li>
              You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other
              way transfer or grant any rights to the App to any third party.{' '}
            </li>
            <li>
              You may not distribute or make the App available over any network where it could be downloaded or used by
              multiple users at the same time, except when it is expressly authorized by Us.
            </li>
            <li>
              You may not use any automatic or manual device or process to interfere with the proper working of the App.
              However, You may use any kind of device or process to delete the App from Your Device.
            </li>
            <li>
              You may not decompile, disassemble, reverse engineer or create derivative works of the App or any parts
              thereof. All modifications or enhancements to the App remain the sole property of Company.
            </li>
            <li>
              You may not use the App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent
              with this EULA or act fraudulently or maliciously, for example, by hacking into or inserting malicious
              code, such as viruses, or harmful data, into the App.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">UPDATES</span>
          <ol className="terms__secondary">
            <li>
              We may, though not obliged, update the App from time to time, making such updates, supplements or new
              versions of the App available to You. If We release an update (including a new version of the App) We may
              request You to update the App. The update may be downloaded and installed automatically or on the basis of
              Your consent depending on the updates settings of Your Device.{' '}
            </li>
            <li>
              The content of any update, supplement or new version of the App is determined exclusively by the Company.
              Under no circumstances do We accept any orders, requires or requests for further development of the App.
              We reserve the right to modify or discontinue any part, or function, or feature of the App at Our own
              discretion with or without a prior notice.
            </li>
            <li>
              If We introduce certain features or functions of the App, We may need to set certain rules for using such
              features or functions. In this case We will introduce changes to the EULA that will take effect on the day
              when such features or functions are released.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">SUBSCRIPTION. TRIAL PERIOD</span>
          <ol className="terms__secondary">
            <li>
              This App as a whole or its certain part (additional Content) is provided on a paid subscription basis. The
              fee may vary depending on the period of the Subscription duration.{' '}
            </li>
            <li>
              The Subscription will renew automatically for the same period at the end of the Subscription at the cost
              of the chosen package. Your Account will be charged for the renewed Subscription within 24 hours prior to
              the end of the current Subscription.
            </li>
            <li>
              You may cancel renewal of the Subscription at least 24 hours before the end of the Subscription: in this
              case the App as a whole or its additional Content will be available to You until the end of the
              Subscription. In order to cancel automatic renewal of the Subscription, please, change subscription
              settings of Your Account. After the end of the Subscription the App as a whole or its additional Content
              will be available only if a new Subscription is purchased.
            </li>
            <li>
              If You uninstall the App, Your subscription will not stop automatically. You must cancel automatic renewal
              of Your subscription in order not to be charged with the cost of the new Subscription.
            </li>
            <li>
              The App provides for a free 3-day trial period. You may use the App of its additional Content free of
              charge during this period. Please, take into consideration that at the end of the trial period it will
              automatically transform into a paid Subscription for the shortest period available and Your Account will
              be charged for the cost of such Subscription, unless You cancel the Subscription through the subscription
              settings of Your Account at least 24 hours prior to the end of the free trial period.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">FEES AND PURCHASE TERMS</span>
          <ol className="terms__secondary">
            <li>The costs of all Subscriptions are available on the web-page of the App on the Platform. </li>
            <li>
              When You purchase a Subscription, the Platform Administrator charges Your Account and collects money on
              Our behalf. The payment is processed by a payment processing system implemented by the Platform
              Administrator.{' '}
            </li>
            <li>
              Please, kindly notice that We neither process Your payment nor have any access to Your payment
              information, such as, among other, Your bank card data, Your name or any other related information.
            </li>
            <li>
              Should You have any question with regard to payment processing, please, submit Your issue to user support
              service of the Platform Administrator.
            </li>
            <li>
              Your Account is charged with the cost of the new Subscription within 24 hours prior the end of the current
              Subscription. If You cancel automatic renewal within the last 24 hours of the Subscription, such
              cancellation will take effect at the end of the new Subscription.
            </li>
            <li>
              Please, kindly notice that We do not make refunds. The digital content is made available to You
              immediately after the Subscription fee is charged to Your Account.{' '}
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">USER-GENERATED CONTENT </span>
          <ol className="terms__secondary">
            <li>
              Some of Our Apps provide You with an opportunity to track analytical data with regard to Your social
              network profile (e.g. Instagram). Functionality of such Apps make it possible to view not only Your posts
              published in a social network, but also posts of other users of this social network, if such users are
              somehow connected to You (for example, they “follow” You). Such posts, whether published by You or by any
              other person, are considered User-generated Content.{' '}
            </li>
            <li>
              As far as You may access posts of other Instagram users, You may be exposed to potentially objectionable
              User-generated Content. Please, take into account that the Company neither controls nor pre-checks any
              User-generated Content posted on any social network.
            </li>
            <li>
              Any kind of modifications or censorship by the Company with regard to the User-generated Content may be
              considered violation of human rights or copyright and may potentially mislead You.
            </li>
            <li>
              If You have been exposed to any kind of objectionable content, You may use functionality of the respective
              social network to track and delete objectionable User-generated Content.
            </li>
            <li>Under no circumstances shall the Company be responsible for any User-generated Content.</li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">RELATED SERVICES AND ADVICE</span>
          <ol className="terms__secondary">
            <li>
              Some of Our Apps are devoted to astrology, horoscopes, palmistry and alike. Please, take into account that
              any kind of data provided to You through the App are provided for information purposes only. It is up to
              You whether to use this information as the basis for Your everyday activities and decisions. Under no
              circumstances shall We be responsible for any consequences of using such information as the basis for Your
              everyday activities and decisions.
            </li>
            <li>
              Some of Our Apps are devoted to fitness, yoga, diets and healthy nutrition, as well as meditation and
              alike. Please, take into account that any kind of data provided to You through the App are provided for
              information purposes only. We strongly recommend You to consult Your doctor or nutrition advisor before
              using any kind of information provided through the App. It is up to You whether to use this information as
              the basis for Your everyday activities and decisions. You use this information at Your own risk. Under no
              circumstances shall We be responsible for any consequences of using such information as the basis for Your
              everyday activities and decisions.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">LINKS TO EXTERNAL SITES AND SERVICES</span>
          <ol className="terms__secondary">
            <li>
              The App may contain links to external sites and services (for example, applications for mobile devices).
              If You decide to visit and/or use such external sites and services, You do this at Your own risk. Under no
              circumstances shall We be responsible for the content of such sites and services. We cannot guarantee that
              these sites and services will be available for You or that Your use of such sites and services will be
              safe.
            </li>
            <li>
              This EULA does not regulate Your relations with owners and/or administrators of such external sites and
              services. Please, check an end user license agreement, terms of service, privacy policy or any other
              related document that regulates usage of external sites and services.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">DISCLAIMER</span>
          <ol className="terms__secondary">
            <li>
              To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents,
              partners, and licensors expressly disclaim all warranties of any kind, express or implied, as to the
              accuracy, adequacy or completeness of any of the information contained in Our Apps or created by the
              services.{' '}
            </li>
            <li>
              The Content in Our Apps is provided to You on an “as is” basis, without any warranties as to
              merchantability or fitness for a particular purpose or use. We do not guarantee that You will attain any
              anticipated result from the use of the Apps.
            </li>
            <li>
              <p>We make no warranty that:</p>
              <ul className="terms__subsecondary">
                <li>the App will meet Your requirements;</li>
                <li>the App will be error-free, secure, or uninterrupted;</li>
                <li>any App functionality errors will be corrected;</li>
                <li>the quality of the App will meet Your expectations.</li>
              </ul>
            </li>
            <li>
              Any service downloaded or otherwise accessed through the use of Our Apps is at Your own discretion and
              risk and We shall have no responsibility or liability for any errors or omissions. We are not responsible
              for any damages, whether direct or indirect, special or consequential, suffered by You or by any other
              person from the use of the Apps, even if You have been advised of the possibility of such damages. No
              advice or information, whether oral or written, obtained by You from Us or Our Apps shall create any
              warranty not expressly stated in the EULA. Under no circumstances shall Our liability or of any of Our
              affiliates pursuant to any cause of action, whether in contract, tort, or otherwise, exceed the fee paid
              by You for access to the Apps. Furthermore, We shall have no responsibility or liability for delays or
              failures due to circumstances beyond Our control.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">TRANSFER OF RIGHTS</span>
          <ol className="terms__secondary">
            <li>
              We may transfer Our rights and obligations under this EULA to any other organization or individual without
              Your consent. We will always tell You in writing if this happens and We will ensure that the transfer will
              not affect Your rights under the EULA.
            </li>
            <li>
              You may only transfer Your rights or Your obligations under this EULA to another person if We agree in
              writing.
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">YOUR VIOLATION OF THE EULA</span>
          <ol className="terms__secondary">
            <li>
              We may end Your rights to use the App at any time by contacting You if You have broken the rules of this
              EULA in a serious way. If what You have done can be put right We will give You a reasonable opportunity to
              do so.
            </li>
            <li>
              <p>If We end Your rights to use the App:</p>
              <ul className="terms__sub-secondary">
                <li>You must stop all activities authorized by this EULA, including Your use of the App; and</li>
                <li>
                  You must delete or remove the App from all Devices and immediately destroy all copies of the App which
                  You have and confirm to Us that You have done this.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <span className="terms__main-list">MISCELLANEOUS</span>
          <ol className="terms__secondary">
            <li>
              Each of the paragraphs of this EULA operates separately. If any court or relevant authority decides that
              any of them is unlawful, the remaining paragraphs will remain in full force and effect.
            </li>
            <li>
              Even if We delay enforcing this EULA, We can still enforce it later. If We do not insist immediately that
              You do anything You are required to do under this EULA terms, or if We delay taking steps against You in
              respect of Your breaking the EULA, that will not mean that You do not have to do those things and it will
              not prevent Us taking steps against You at a later date.
            </li>
            <li>
              This EULA shall be governed and construed in accordance with the laws of New York, United States of
              America, without regard to its conflict of law provisions.
            </li>
            <li>
              <p>
                If any dispute, controversy or difference arises between You and the Company, it shall be settled
                amicably through negotiations. In case such dispute, controversy or difference cannot be settled
                amicably through negotiations within a thirty 30-day period it shall be shall be settled by the American
                Arbitration Association (the AAA) in accordance with its International Expedited Procedures.
              </p>
              <p>The number of arbitrators shall be one.</p>
              <p>The place of arbitration shall be New York, New York, the United States of America.</p>
              <p>The arbitration shall be held, and the award rendered, in English.</p>
              <p>
                The appointing authority shall be the AAA acting in accordance with the rules adopted by the AAA for
                this purpose.
              </p>
              <p>
                Each party (i.e. You and the Company) shall bear its own expenses, but the parties shall share equally
                the expenses of the arbitration tribunal.
              </p>
            </li>
            <li>
              Support. If You want to learn more about the App or have any problems using it, please, contact Us at{' '}
              <a style={{ textDecoration: 'underline' }} href={`mailto:${APP_CONSTANTS.EMAIL}`}>
                {APP_CONSTANTS.EMAIL}
              </a>
              .
            </li>
          </ol>
        </li>
      </div>
    </div>
  );
};
