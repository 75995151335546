import React from "react";
import Palms from '../assets/images/palms.png';
import {Link} from 'react-router-dom';
import {Navigate} from 'react-router';

import {Processing} from '../forms/index';

export class PalmReadingLeftHand extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            images: '',
            shouldRedirect: false,
            numberOfErrors:0
        }
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.step)
    }

    onKeyDown = (keyEvent) => {
        if (!(this.props.values.idealWeight) && (keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    uploadFile = (event) => {
        const formdata = new FormData();
        formdata.append("image", event.target.files[0], event.target.files[0].name);

        this.setState({isLoading: true})
        fetch(`https://content-admin.appyfurious.com/api/v5/analyzeHand`, {
            method: 'POST',
            body: formdata
        })
            .then((response) =>
                response.json()
            )
            .then((data) => {
                if (data.hand === 'LEFT') {
                    this.setState({shouldRedirect: true})
                    this.setState({isLoading: false});
                } else if (data.hand === 'RIGHT') {
                    this.setState({
                        error: 'Please, use your left hand.',
                        isLoading: false,
                        numberOfErrors: this.state.numberOfErrors + 1
                    });
                } else {
                    this.setState({
                        error: 'Please, add an image of your hand',
                        isLoading: false,
                        numberOfErrors: this.state.numberOfErrors + 1
                    });
                }

            })
            .catch(() => {
                this.setState({
                    error: 'Error, try again',
                    isLoading: false,
                    numberOfErrors: this.state.numberOfErrors + 1
                })
            });
    }

    render() {
        if (this.state.shouldRedirect) {
            return <Navigate push to="/right-hand"/>;
        }
        return (
            <div className="quiz-step quiz-palms">
                <div className="quiz-header">
                    <div className="quiz-header__title h2">
                        Take a photo of your left palm
                    </div>
                </div>
                <div className="quiz-palms__image">
                    <img src={Palms} alt=""/>
                </div>

                <div className="quiz-bottom">
                    <Link to={{
                        pathname: '/left-hand-photo',
                    }}
                          className="quiz-button">
                        <span>Take a photo</span>
                    </Link>
                    <div className="form-group">
                        <input id="photo" name="photo" type="file" className="form-control" accept="image/*"
                               onChange={this.uploadFile}
                        />
                        <div className="quiz-gallery">
                            Choose from gallery
                        </div>
                        {this.state.error ? (
                            <div className="quiz-form__input__error">{this.state.error}</div>
                        ) : null}
                    </div>
                </div>
                {this.state.isLoading ? <Processing/> : console.log('DOM ready')}
            </div>
        );
    }
};
